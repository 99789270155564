import "@web/styles/_index.scss";
import "bootstrap/dist/css/bootstrap-utilities.css";
import { config } from "@fortawesome/fontawesome-svg-core";
import { type Session } from "next-auth";
import { Montserrat } from "next/font/google";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { queryClient } from "@web/lib/query";
import { type AppProps } from "next/app";
import Head from "next/head";
import "@web/styles/globals.css";

config.autoAddCss = false;

const montserrat = Montserrat({
    subsets: ["latin"],
});

// const fontSans = FontSans({
//     subsets: ["latin"],
//     variable: "--font-sans",
// });

function Waveous({ Component, pageProps: { session, ...pageProps } }: AppProps<{ session: Session }>) {
    return (
        <QueryClientProvider client={queryClient}>
            <style jsx global>{`
                html {
                    font-family: ${montserrat.style.fontFamily};
                }
            `}</style>
            <Head>
                <link rel="icon" href="/waveous_logo.svg" />
            </Head>
            <Component {...pageProps} />
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
}

// Waveous.getInitialProps = async (appContext: AppContext) => {
//     // calls page's `getInitialProps` and fills `appProps.pageProps`
//     const appProps = await App.getInitialProps(appContext);
//
//     if (appContext.ctx.res?.statusCode === 404) {
//         appContext.ctx.res.writeHead(302, { Location: "/" });
//         appContext.ctx.res.end();
//         return;
//     }
//
//     return { ...appProps };
// };

export default Waveous;
