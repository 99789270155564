import { QueryClient } from "@tanstack/react-query";
import { isAxiosError } from "axios";

const MAX_RETRIES = 3;
const HTTP_STATUS_TO_NOT_RETRY = [400, 401, 403, 404];

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            gcTime: 1000 * 60 * 60 * 24, // 24 hours
            staleTime: 1000 * 60 * 60 * 24, // 24 hours
            retry: (failureCount, error) => {
                if (failureCount >= MAX_RETRIES) {
                    return false;
                }

                console.log(error);

                if (isAxiosError(error) && HTTP_STATUS_TO_NOT_RETRY.includes(error.response?.status ?? 0)) {
                    console.log(`> Aborting retry due to ${error.response?.status} status`);
                    return false;
                }

                return true;
            },
            // first delay os 1s, double until max of 10s
            // assuming 3 retries, should delay for 1s, 2s, then 4s
            retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 10000),
        },
    },
});
